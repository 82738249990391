export const TXT_PAGINA_PSICOTERAPIA = {
    TITULO_PSICOTERAPIA_1: 'O que é psicoterapia?',
    TITULO_PSICOTERAPIA_2: 'Qual o público que atendo?',
    TITULO_PSICOTERAPIA_3: 'Qual a minha abordagem?',
    TITULO_PSICOTERAPIA_4: 'Perguntas Frequentes',
    TXT_FOCO: `A psicoterapia é um tratamento onde se constrói uma relação
            terapeutica entre o paciente e o psicólogo, no qual irão trabalhar
            as demandas emocionais, psicológicas e comportamentais. As consultas
            são realizadas de forma online e a frequência depende do
            profissional e da necessidade do paciente. Podendo ser semanal,
            quinzenal ou até mensal.`,
    TXT_PUBLICO_ATENDIDO: `Atendo adolescentes, adultos e idosos que estão em busca de lidar
            melhor com suas emoções, ansiedade, procrastinação, depressão, trabalhar o
            autoconhecimento e desenvolver habilidades para ter uma vida mais
            leve.`,
    TXT_QUAL_MINHA_ABORDAGEM: `A abordagem que trabalho é a Terapia Cognitivo Comportamental e a Terapia de Aceitação e Compromisso.
            Essas duas abordagens de terapia são baseadas em evidências, sendo as mais recomendadas para casos de transtorno de personalidade,
            ansiedade, depressão e desregulação emocional no geral.
            Também auxilia pessoas em seu desenvolvimento pessoal e melhorar sua forma de lidar com as questões cotidianas de maneira geral.
            Dessa forma, a terapia também tem um olhar preventivo sobre a saúde mental, buscando ajudar as pessoas a melhorar relacionamentos,
            gerenciar suas emoções e potencializar suas virtudes e valores para uma vida mais leve.`,
};

export const PERGUNTAS_FREQUENTES = [
    {
        id: 0,
        PERGUNTA: `Quem pode fazer terapia?`,
        RESPOSTA: `Qualquer pessoa que estiver disposta ou que precise investir em si mesmo e trabalhar suas questões.`,
    },
    {
        id: 1,
        PERGUNTA: `Quanto tempo dura o tratamento?`,
        RESPOSTA: `Não é possível mensurar o tempo do tratamento, pois depende da individualidade e queixas de cada um.`,
    },
    {
        id: 2,
        PERGUNTA: `Quanto custa?`,
        RESPOSTA: `O código de ética de Psicologia permite que cada profissional estipule o preço do seu trabalho, tendo em base a tabela do Conselho Federal de Psicologia. Entre em contato para saber mais sobre meu trabalho.`,
    },
];
