export const TXT_DEPOIMENTOS = {
    DEPOIMENTOS: 'Depoimentos',
    DEPOIMENTO_1:
        'Faço acompanhamento com a Laís desde final de 2020, época na qual ainda enfrentávamos uma pandemia, cheia de incertezas, inseguranças e eu passava por um período de depressão e ansiedade. A terapia veio para me ajudar e me entender, entender outras pessoas do meu convívio e ter uma melhor relação com a vida, foi quando me senti preparada para iniciar o desmame de medicações antidepressivas e foi muito mais tranquilo do que imaginava. Desde então me sinto cada vez melhor, é meu momento de autocuidado que não abro mão.',
    AUTOR_1: 'M. F.',
    DEPOIMENTO_2: `Queria dizer o quanto sou grata por tudo que você fez por mim durante o tempo em que temos trabalhado juntas. Sua ajuda tem sido muito especial.
Obrigada por me ajudar a entender os gatilhos da minha ansiedade e por me ensinar a ser mais gentil comigo mesma. Isso realmente está mudando minha vida para melhor!
E o mais importante, obrigada por estar ao meu lado durante uma fase tão difícil da minha vida. Sem você, tenho certeza de que teria sido impossível enfrentar tudo aquilo de maneira mais leve. Sua dedicação à saúde mental e crescimento pessoal dos seus pacientes é admirável.
Vamos continuar juntas nessa jornada em busca de mais autoconhecimento e maneiras de lidar com os desafios e imprevistos da vida. Seu apoio é essencial! `,
    AUTOR_2: `B. Y.`,
    DEPOIMENTO_3:
        'Minha querida psicóloga Laís meu depoimento sobre seu trabalho e como está me ajudando. Bom a cada dia que passa de terapia só tenho agradecer o trabalho da Laís. O motivo que me levou a terapia foi a morte de meu pai com quem eu sempre fui apegada. Também fiquei muito sobrecarregada pois estou cuidando de minha mãe e  com a perda de um dos meus irmãos minha irmã adoeceu então tudo ficou pra mim. Eu estava esgotada procurei ajuda psiquiatra que também me aconselhou a ir fazer terapia. Então conheci Laís através da dra Vanessa. Ela foi e está sendo uma pessoa , amiga e uma ótima profissional. Me ajudou no momento do luto e hoje posso dizer que estou bem melhor em vários sentidos da minha vida. Ajudou a me livrar de medicamentos aos poucos e hoje estou bem. Contínuo com as terapias até o momento que ela como profissional me liberar. Laís posso dizer que é uma profissional que Deus providenciou no meu caminho e aconselho à todos que ela seja também uma psicóloga continuar fazendo seu trabalho e ajudando pessoas a serem felizes. Obrigada por tudo até hoje Laís.',
    AUTOR_3: 'I. S.',
    DEPOIMENTO_4: `Eu conheci a Psicóloga Lais Ferreira, em um momento da minha vida, que eu me encontrava totalmente sem me enxergar, não sabia meu valor e não conseguia nenhuma saída para o que eu estava vivendo. Foi uma indicação, e umas das melhores escolhas da minha vida. Eu vi que haviam coisas que não eram minhas que eu carregava, que posso sim ter controle sobre minhas emoções e que sou uma mulher linda, forte, guerreira e que não posso aceitar menos do que mereço. Eu fiz acompanhamento, devido ao processo de divórcio. Eu ainda sigo no processo, porém melhor que ontem e sem dúvidas amanhã serei um exemplo para muitas. E digo: Quem tem uma Lais, tem tudo!`,
    AUTOR_4: 'N. P.',
};
