import { makeStyles } from '@material-ui/core';
import banner from '../../assets/img/banner.jpeg';

export const useStyles = makeStyles((theme) => ({
    banner: {
        width: '100%',
        backgroundImage: `url(${banner})`,
        height: '700px',
        backgroundPosition: 'center',
        backgroundPositionY: '33%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',

        [theme.breakpoints.down('sm')]: {
            height: '500px',
        },
    },
}));
