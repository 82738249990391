import { Typography } from '@material-ui/core';
import React from 'react';
import { motion } from 'framer-motion/dist/framer-motion';
import { useStyles } from './styles';

export const Title = ({ isUnderline, isSubTitle, title, textAlign }) => {
    const classe = useStyles({ isSubTitle, isUnderline, textAlign });
    return (
        <motion.div
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
        >
            <Typography className={classe.title}>{title}</Typography>
        </motion.div>
    );
};
