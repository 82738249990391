import { Grid, Typography } from '@material-ui/core';
import InstagramIcon from '@material-ui/icons/Instagram';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import React from 'react';
import TikTokIcon from '../../assets/img/tik-tok-icon.png';
import { WHATSAPP_ATENDIMENTO_TXT } from '../../constants/text';

import { useStyles } from './styles';

export const Footer = () => {
    const classes = useStyles();
    return (
        <Grid
            container
            className={classes.container}
            justify="center"
            alignItems="center"
            direction="column"
        >
            <Grid
                container
                className={classes.containerIcons}
                justify="space-between"
                alignItems="center"
            >
                <a
                    href={WHATSAPP_ATENDIMENTO_TXT}
                    target="_blank"
                    rel="noreferrer"
                >
                    <WhatsAppIcon fontSize="small" />
                </a>
                <a
                    href="https://www.instagram.com/psilaisferreira/"
                    target="_blank"
                    rel="noreferrer"
                >
                    <InstagramIcon fontSize="small" />
                </a>
                <a
                    href="https://www.tiktok.com/@laisferreirapsicologa"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img
                        src={TikTokIcon}
                        alt="Tik tok icon"
                        className={classes.tikTok}
                    />
                </a>
            </Grid>
            <Typography className={classes.copyRight}>
                {`©${new Date().getUTCFullYear()} Todos direitos reservados`}
            </Typography>
        </Grid>
    );
};
