import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    whats: {
        padding: theme.spacing(1),
        borderRadius: '5px',
        height: '54px',
        width: '54px',
        backgroundColor: theme.appColors.whatsGreen,
        position: 'fixed',
        right: 32,
        bottom: 32,

        '& > img:hover': {
            filter: 'brightness(0.8)',
        },
    },
}));
