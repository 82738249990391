import { Grid, Hidden } from '@material-ui/core';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MENUS } from '../../constants/commons';
import ROUTES_NAME from '../../constants/routes-name';
import HamburguerMenu from '../HamburguerMenu';

import { ScheduleButton } from '../ScheduleButton';
import { useStyles } from './styles';

function Header() {
    const classes = useStyles();
    const history = useHistory();
    const [menuActive, setMenuActive] = useState();

    const handleClickItemMenu = async (e, menuId) => {
        setMenuActive(menuId);
    };

    useEffect(() => {
        setMenuActive(history.location.pathname);
    }, [history?.location?.pathname]);

    return (
        <header>
            <Hidden smDown>
                <Grid
                    container
                    justify="flex-end"
                    alignItems="center"
                    className={classes.gridContainer}
                >
                    <Grid
                        container
                        justify="space-around"
                        alignItems="center"
                        className={classes.containerMenu}
                    >
                        <a
                            className={clsx(
                                classes.itemMenu,
                                menuActive === ROUTES_NAME.SOBRE_MIM
                                    ? classes.menuActive
                                    : null
                            )}
                            onClick={(e) =>
                                handleClickItemMenu(e, ROUTES_NAME.SOBRE_MIM)
                            }
                            href={`#${ROUTES_NAME.SOBRE_MIM}`}
                        >
                            {MENUS.SOBRE_MIM}
                        </a>

                        <a
                            className={clsx(
                                classes.itemMenu,
                                menuActive === ROUTES_NAME.PSICOTERAPIA
                                    ? classes.menuActive
                                    : null
                            )}
                            onClick={(e) =>
                                handleClickItemMenu(e, ROUTES_NAME.PSICOTERAPIA)
                            }
                            href={`#${ROUTES_NAME.PSICOTERAPIA}`}
                        >
                            {MENUS.PSICOTERAPIA}
                        </a>

                        <a
                            className={clsx(
                                classes.itemMenu,
                                menuActive === ROUTES_NAME.MINHA_ABORDAGEM
                                    ? classes.menuActive
                                    : null
                            )}
                            onClick={(e) =>
                                handleClickItemMenu(
                                    e,
                                    ROUTES_NAME.MINHA_ABORDAGEM
                                )
                            }
                            href={`#${ROUTES_NAME.MINHA_ABORDAGEM}`}
                        >
                            {MENUS.MINHA_ABORDAGEM}
                        </a>

                        <a
                            className={clsx(
                                classes.itemMenu,
                                menuActive === ROUTES_NAME.CONSULTORIA
                                    ? classes.menuActive
                                    : null
                            )}
                            onClick={(e) =>
                                handleClickItemMenu(e, ROUTES_NAME.CONSULTORIA)
                            }
                            href={`#${ROUTES_NAME.CONSULTORIA}`}
                        >
                            {MENUS.CONSULTORIA}
                        </a>

                        <ScheduleButton />

                        <a
                            href="https://sun.eduzz.com/1620380"
                            target="_blank"
                            rel="noreferrer"
                            className={clsx(
                                classes.itemMenu,
                                menuActive === ROUTES_NAME.EBOOK
                                    ? classes.menuActive
                                    : null
                            )}
                        >
                            {MENUS.EBOOK}
                        </a>
                    </Grid>
                </Grid>
            </Hidden>
            <Hidden mdUp>
                <HamburguerMenu />
            </Hidden>
        </header>
    );
}

export default Header;
