/* eslint-disable jsx-a11y/media-has-caption */
import { Grid } from '@material-ui/core';
import axios from 'axios';
import React, { useEffect, useState, useMemo } from 'react';
import Instagram from '@material-ui/icons/Instagram';
import { useStyles } from './styles';
import { Loading } from '../../components/Loading';
import { TXT_GERAL } from '../../constants/text';
import { Title } from '../../components/Title/index';

export default function InstaFeed() {
    const classes = useStyles();
    const [feedList, setFeedList] = useState([]);
    const [loading, setLoading] = useState(false);

    async function getInstaFeed() {
        setLoading(true);
        const token = process.env.REACT_APP_INSTA_TOKEN;
        const fields = 'media_url,media_type,permalink,thumbnail_url';
        const url = `https://graph.instagram.com/me/media?access_token=${token}&fields=${fields}`;

        const { data } = await axios.get(url).finally(() => setLoading(false));

        setFeedList(data?.data.slice(0, 9));
    }

    const renderFeed = useMemo(() => {
        return feedList?.length > 0 ? (
            <Grid
                container
                justify="center"
                direction="column"
                className={classes.container}
            >
                <Title title={TXT_GERAL.INSTAGRAM} />
                <Grid className={classes.feedContainer}>
                    {feedList?.map((item) => (
                        <a
                            key={item.id}
                            href={item.permalink}
                            target="_blank"
                            rel="noreferrer"
                            className={classes.item}
                        >
                            <Instagram className={classes.instragram} />
                            {item.media_type === 'VIDEO' ? (
                                <img
                                    src={item.thumbnail_url}
                                    alt="instagram post"
                                    width="293px"
                                    height="293px"
                                />
                            ) : (
                                <img
                                    src={item.media_url}
                                    alt="instagram post"
                                    width="293px"
                                    height="293px"
                                />
                            )}
                        </a>
                    ))}
                </Grid>
            </Grid>
        ) : null;
    }, [feedList]);

    useEffect(() => {
        getInstaFeed();
    }, []);

    return loading ? <Loading withMinHeight /> : renderFeed;
}
