import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    gridContainer: {
        padding: theme.spacing(3, 7),
        position: 'fixed',
        top: 0,
        zIndex: 999,
        maxWidth: '100vw',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(3),
        },
    },
    nomeHeader: {
        fontSize: 20,
        textDecoration: 'none',
        fontWeight: 400,
        color: 'rgba(0,0,0,0.6)',
        transition: 'color 0.4s ease 0s',
        '&:hover': {
            color: theme.appColors.gray,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
        },
    },
    containerMenu: {
        width: 750,
        backgroundColor: theme.appColors.white,
        padding: theme.spacing(2),
        borderRadius: '12px',

        [theme.breakpoints.only('sm')]: {
            width: 550,
        },
    },
    itemMenu: {
        color: 'black',
        fontSize: '1em',
        fontWeight: 'bold',
        transition: 'color 0.4s ease 0s',
        cursor: 'pointer',
        '&:hover': {
            opacity: 0.8,
        },
    },
    menuActive: {
        textDecoration: 'underline',
    },
    subMenuContainer: {
        backgroundColor: theme.palette.primary,
    },
    icon: {
        width: 20,
        height: 20,
        marginRight: theme.spacing(1),
    },
}));
