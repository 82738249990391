import Grid from '@material-ui/core/Grid';
import React from 'react';

import { useMediaQuery, useTheme } from '@material-ui/core';
import { useInView } from 'react-intersection-observer';
import { useStyles } from './styles';
import { BotaoFlutuanteWhats } from '../../components/BotaoFlutuanteWhats';
import Banner from '../../components/Banner/index';
import AgendeSeuHorario from '../../components/AgendeSeuHorario/index';
import SobreMimPage from '../SobreMim/index';
import PsicoterapiaPage from '../Psicoterapia/index';
import MinhaAbordagemPage from '../MinhaAbordagem/index';
import ConsultoriaPage from '../Consultoria/index';
import Depoimentos from '../../components/Depoimentos/index';
import InstaFeed from '../InstaFeed/index';

const HomePage = () => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const { ref, inView } = useInView({
        triggerOnce: true,
        rootMargin: '200px 0px',
    });

    return (
        <Grid container className={classes.gridContainer}>
            <Banner />

            {isMobile ? <AgendeSeuHorario /> : null}

            <SobreMimPage />

            <PsicoterapiaPage />

            <MinhaAbordagemPage />

            <ConsultoriaPage />

            <Depoimentos />

            <Grid container ref={ref}>
                {inView ? <InstaFeed /> : null}
            </Grid>

            <BotaoFlutuanteWhats />
        </Grid>
    );
};

export default HomePage;
