import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    btn: {
        padding: '8px 16px',
        borderRadius: '12px',
        height: '54px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.appColors.whatsGreen,

        '&>p': {
            color: '#fff',
            fontWeight: 'bold',
        },
        '&:hover': {
            filter: 'brightness(0.8)',
        },

        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    text: {
        fontSize: '14px',
        color: 'black',
    },
    icon: {
        width: 24,
        height: 24,
        marginRight: theme.spacing(2),
    },
}));
