import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    containerImg: {
        height: '100%',
    },
    img: ({ urlFoto }) => ({
        width: '100%',
        height: '100%',
        minHeight: '1350px',
        backgroundImage: `url(${urlFoto})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',

        [theme.breakpoints.down('sm')]: {
            height: '700px',
        },
    }),
}));
