import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { ScheduleButton } from '../ScheduleButton';
import { TXT_GERAL } from '../../constants/text';
import { useStyles } from './styles';

const AgendeSeuHorario = () => {
    const classes = useStyles();

    return (
        <Grid
            container
            justify="center"
            alignItems="center"
            direction="column"
            className={classes.container}
        >
            <Grid item>
                <Typography className={classes.txt} align="center">
                    {TXT_GERAL.AGENDE_SEU_HORARIO_COMIGO}
                </Typography>
            </Grid>
            <Grid item>
                <ScheduleButton />
            </Grid>
        </Grid>
    );
};

export default AgendeSeuHorario;
