import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    txtPergunta: {
        fontSize: 18,
        textAlign: 'left',
        lineHeight: '1.8em',
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('md')]: {
            whiteSpace: 'pre-wrap',
        },
    },
    txtResposta: {
        display: 'flex',
        flexDirection: 'column',
        '& > p,li': {
            textAlign: 'justify',
            lineHeight: '1.8em',
            textIndent: '2em',
            fontSize: 14,
        },
    },
}));
