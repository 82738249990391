import React from 'react';
import { useInView } from 'react-intersection-observer';
import { useStyles } from './styles';

const LazyImage = ({ urlFoto }) => {
    const classes = useStyles({ urlFoto });
    const { ref, inView } = useInView({
        triggerOnce: true,
        rootMargin: '200px 0px',
    });

    return (
        <div ref={ref} className={classes.containerImg}>
            {inView ? <div className={classes.img} ref={ref} /> : null}
        </div>
    );
};

export default LazyImage;
