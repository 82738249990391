import Typography from '@material-ui/core/Typography';
import React from 'react';
import whats from '../../assets/img/whatsapp.png';
import {
    TXT_GERAL,
    WHATSAPP_ATENDIMENTO_TXT,
    WHATSAPP_ATENDIMENTO_TXT_CONSULTORIA,
} from '../../constants/text';
import { useStyles } from './styles';

export const ScheduleButton = ({ isConsultoria }) => {
    const classes = useStyles();

    const text = isConsultoria
        ? TXT_GERAL.AGENDE_SUA_CONSULTORIA
        : TXT_GERAL.AGENDE_SEU_HORARIO;

    const url = isConsultoria
        ? WHATSAPP_ATENDIMENTO_TXT_CONSULTORIA
        : WHATSAPP_ATENDIMENTO_TXT;

    return (
        <a className={classes.btn} href={url} target="_blank" rel="noreferrer">
            <img src={whats} alt="whattsapp" className={classes.icon} />
            <Typography className={classes.text}>{text}</Typography>
        </a>
    );
};
