import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(6, 6, 0, 6),

        '&>div:last-child': {
            marginTop: theme.spacing(2),
        },
    },
    txt: {
        fontSize: '1.4em',
    },
}));
