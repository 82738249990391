import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    title: ({ isSubTitle, isUnderline, textAlign }) => ({
        fontSize: isSubTitle ? '2em' : '2.5em',
        fontWeight: 'bold',
        textDecoration: isUnderline ? 'underline' : 'none',
        marginBottom: theme.spacing(4),
        textAlign: textAlign || 'center',
    }),
}));
