import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(12),

        '& > .carousel-root': {
            maxWidth: '100%',
        },
        '& .slider-wrapper': {
            paddingBottom: 10,
        },
    },
    arrow: {
        position: 'absolute',
        zIndex: 2,
        top: 'calc(50% - 15px)',
        width: 30,
        height: 30,
        cursor: 'pointer',
        backgroundColor: theme.palette.primary.main,
        borderRadius: '50%',
        color: theme.appColors.white,
    },
}));
