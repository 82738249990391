import { CircularProgress, Grid } from '@material-ui/core';
import React from 'react';
import { useStyles } from './styles';

export const Loading = ({ withMinHeight }) => {
    const classes = useStyles({ withMinHeight });
    return (
        <Grid
            item
            container
            xs={12}
            justify="center"
            alignContent="center"
            className={classes.gridContainer}
        >
            <CircularProgress />
        </Grid>
    );
};
