import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(4),
    },

    feedContainer: {
        width: '100%',
        maxWidth: '1200px',
        margin: '0 auto',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(293px, 1fr))',
        gap: '1.5rem',
        padding: '1rem',
    },

    item: {
        borderRadius: '1rem',
        overflow: 'hidden',
        transition: '0.4s',
        width: '293px',
        height: '293px',
        margin: '0 auto',
        position: 'relative',

        '& img, video': {
            maxWidth: '100%',
            maxHeight: '100%',
            objectFit: 'cover',
            display: 'block',
            width: '100%',
        },
        '&:hover': {
            filter: 'brightness(0.6)',
        },
        '&:hover > svg': {
            opacity: '1',
        },
    },

    titulo: {
        fontFamily: 'Palatino-italico',
        fontSize: 56,
        textAlign: 'center',
        lineHeight: '1.8em',
        padding: theme.spacing(3, 0),
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('md')]: {
            whiteSpace: 'pre-wrap',
        },
    },

    instragram: {
        position: 'absolute',
        fontSize: 48,
        opacity: 0,
        color: 'white',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        margin: 'auto',
        transition: '0.4s',
    },
}));
