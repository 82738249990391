import React from 'react';
import { useStyles } from './styles';

const Banner = () => {
    const classes = useStyles();

    return <div className={classes.banner} />;
};

export default Banner;
