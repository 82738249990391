import { ThemeProvider } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createBrowserHistory } from 'history';
import React from 'react';
import { Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Footer } from './components/Footer';
import Header from './components/Header';
import AppRoutes from './routes';
import AppTheme from './theme/index';

import './theme/styles.css';

const history = createBrowserHistory();

export default function App() {
    return (
        <Router history={history}>
            <ThemeProvider theme={AppTheme}>
                <CssBaseline />
                <Header />
                <ToastContainer limit={2} />
                <AppRoutes />
                <Footer />
            </ThemeProvider>
        </Router>
    );
}
