import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import React, { useState } from 'react';
import logo from '../../assets/img/logo-preto-branco.png';
import { MENUS } from '../../constants/commons';
import ROUTES_NAME from '../../constants/routes-name';
import { WHATSAPP_ATENDIMENTO_TXT } from '../../constants/text';
import whats from '../../assets/img/whatsapp.png';
import { useStyles } from './styles';

export default function HamburgueMenu() {
    const classes = useStyles();

    const [open, setOpen] = useState(false);

    const handleDrawer = (value) => {
        setOpen(value);
    };

    const handleCloseMenu = () => {
        setOpen(false);
    };

    return (
        <div>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        aria-label="open drawer"
                        onClick={() => handleDrawer(true)}
                        edge="end"
                    >
                        <MenuIcon />
                    </IconButton>
                    <img className={classes.logo} src={logo} alt="logo" />
                </Toolbar>
            </AppBar>
            <Drawer
                className={classes.drawer}
                variant="temporary"
                anchor="top"
                open={open}
                onClose={() => handleDrawer(false)}
            >
                <List>
                    <ListItem button>
                        <ListItemText onClick={() => handleCloseMenu()}>
                            <a href={`#${ROUTES_NAME.SOBRE_MIM}`}>
                                {MENUS.SOBRE_MIM}
                            </a>
                        </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem button>
                        <ListItemText onClick={() => handleCloseMenu()}>
                            <a href={`#${ROUTES_NAME.PSICOTERAPIA}`}>
                                {MENUS.PSICOTERAPIA}
                            </a>
                        </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem button>
                        <ListItemText onClick={() => handleCloseMenu()}>
                            <a href={`#${ROUTES_NAME.MINHA_ABORDAGEM}`}>
                                {MENUS.MINHA_ABORDAGEM}
                            </a>
                        </ListItemText>
                    </ListItem>
                    <Divider />

                    <ListItem button>
                        <ListItemText onClick={() => handleCloseMenu()}>
                            <a href={`#${ROUTES_NAME.CONSULTORIA}`}>
                                {MENUS.CONSULTORIA}
                            </a>
                        </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem button className={classes.menuAgendeHorario}>
                        <a
                            href={WHATSAPP_ATENDIMENTO_TXT}
                            target="_blank"
                            rel="noreferrer"
                            className={classes.containerAgendeSeuHorario}
                        >
                            <img
                                src={whats}
                                alt="whattsapp"
                                className={classes.icon}
                            />
                            <Typography className={classes.text}>
                                {MENUS.AGENDE_SEU_HORARIO}
                            </Typography>
                        </a>
                    </ListItem>
                    <Divider />
                    <ListItem button>
                        <ListItemText onClick={() => handleCloseMenu()}>
                            <a
                                href="https://sun.eduzz.com/1620380"
                                target="_blank"
                                rel="noreferrer"
                            >
                                {MENUS.EBOOK}
                            </a>
                        </ListItemText>
                    </ListItem>
                </List>
            </Drawer>
        </div>
    );
}
