import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import ROUTES_NAME from '../../constants/routes-name';
import LazyImage from '../../components/LazyImage';
import foto from '../../assets/img/foto-minha-abordagem.jpg';
import { MINHA_ABORDAGEM } from '../../constants/minha-abordagem';

import { Title } from '../../components/Title';
import { useStyles } from './styles';

export default function MinhaAbordagemPage() {
    const classes = useStyles();

    return (
        <Grid
            container
            className={classes.gridContainer}
            id={ROUTES_NAME.MINHA_ABORDAGEM}
        >
            <Grid item xs={12} md={6}>
                <LazyImage urlFoto={foto} />
            </Grid>

            <Grid
                item
                container
                xs={12}
                md={6}
                justify="center"
                alignContent="flex-start"
                className={classes.subContainer}
            >
                <Title title={MINHA_ABORDAGEM.TITLE} />
                <Typography className={classes.txtDescricao}>
                    {MINHA_ABORDAGEM.DESCRIPTION}
                </Typography>
            </Grid>
        </Grid>
    );
}
