export const TXT_CONTATO = {
    TITULO:
        'Preencha os campos abaixo para receber meu conteúdo gratuitamente:',
};

export const TOASTFY_MSG = {
    EMAIL_SUCESSO: 'Email enviado com sucesso!',
    EMAIL_ERRO:
        'Ocorreu algum erro ao enviar o email, por favor tente novamente mais tarde.',
};

export const WHATSAPP_ATENDIMENTO_TXT = `https://api.whatsapp.com/send?phone=5532988822909&text=Olá tudo bem? Eu gostaria de saber mais sobre o seu atendimento!`;
export const WHATSAPP_ATENDIMENTO_TXT_CONSULTORIA = `https://api.whatsapp.com/send?phone=5532988822909&text=Olá tudo bem? Eu gostaria de saber mais sobre a consultoria PSI DO ZERO!`;

export const TXT_GERAL = {
    AGENDE_SEU_HORARIO: 'Agende sua sessão',
    AGENDE_SUA_CONSULTORIA: 'Agende sua consultoria',
    AGENDE_SEU_HORARIO_COMIGO: 'Agende sua sessão comigo através do whatsapp',
    INSTAGRAM: 'Instagram',
};
