import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    gridContainer: {
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    subContainer: {
        padding: theme.spacing(10),
        [theme.breakpoints.only('sm')]: {
            padding: theme.spacing(8),
        },
        [theme.breakpoints.only('xs')]: {
            padding: theme.spacing(4),
        },

        '& > :nth-child(1)': {
            paddingTop: 0,
        },
    },

    txtDescricao: {
        textAlign: 'justify',
        lineHeight: '1.8em',
        textIndent: '2em',
        fontSize: 16,
        paddingBottom: theme.spacing(4),

        '&li > p': {
            textIndent: '0',
        },
    },

    txtLista: {
        textAlign: 'justify',
        lineHeight: '1.8em',
        fontSize: 16,
    },
    bold: { fontWeight: 'bold' },
    textFraseFinal: {
        marginTop: theme.spacing(8),
        textAlign: 'justify',
        lineHeight: '1.8em',
        textIndent: '2em',
        fontSize: 16,
        fontWeight: 'bold',
        '&li > p': {
            textIndent: '0',
        },
    },
    lista: { margin: theme.spacing(0, 0, 8) },
    scheduleButton: { marginTop: theme.spacing(8) },
}));
