import React from 'react';
import { Redirect } from 'react-router';
import ROUTES_NAME from '../constants/routes-name';
import HomePage from '../pages/Home';

const routes = [
    {
        path: '/',
        component: HomePage,
    },
    {
        path: ROUTES_NAME.HOME,
        component: HomePage,
        exact: true,
    },
    {
        path: ROUTES_NAME.PAGE_NOT_FOUND,
        component: () => <Redirect to={ROUTES_NAME.SOBRE_MIM} />,
        exact: true,
    },
];

export default routes;
