import { Divider } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { TXT_SOBRE_MIM } from '../../constants/sobre-mim';

import { useStyles } from './styles';
import { Title } from '../../components/Title';
import { TimeLine } from '../../components/TimeLine';
import ROUTES_NAME from '../../constants/routes-name';

export default function SobreMimPage() {
    const classes = useStyles();

    return (
        <Grid container justify="center" id={ROUTES_NAME.SOBRE_MIM}>
            <Grid container justify="center" className={classes.gridContainer}>
                <Title title={TXT_SOBRE_MIM.QUEM_SOU_EU} />
                <Typography className={classes.txtPrincipal}>
                    {TXT_SOBRE_MIM.PARAGRAFO_1}
                </Typography>
                <Typography className={classes.txtPrincipal}>
                    {TXT_SOBRE_MIM.PARAGRAFO_2}
                </Typography>

                <TimeLine />

                <Grid item xs={12}>
                    <Divider variant="middle" className={classes.divider} />
                </Grid>
            </Grid>
        </Grid>
    );
}
