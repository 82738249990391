import React from 'react';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import { TXT_SOBRE_MIM } from '../../constants/sobre-mim';

export const TimeLine = () => {
    return (
        <Timeline align="alternate">
            <TimelineItem>
                <TimelineSeparator>
                    <TimelineDot variant="outlined" color="secondary" />
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>{TXT_SOBRE_MIM.GRADUACAO_1}</TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineSeparator>
                    <TimelineDot variant="outlined" color="primary" />
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                    {TXT_SOBRE_MIM.ESPECIALIZACAO_1}
                </TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineSeparator>
                    <TimelineDot variant="outlined" />
                </TimelineSeparator>
                <TimelineContent>
                    {TXT_SOBRE_MIM.ESPECIALIZACAO_ATUAL}
                </TimelineContent>
            </TimelineItem>
        </Timeline>
    );
};
