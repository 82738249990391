export const COMMONS = {
    NOME: 'Laís Ferreira',
};

export const MENUS = {
    SOBRE_MIM: 'Sobre Mim',
    PSICOTERAPIA: 'Psicoterapia',
    CONSULTORIA: 'Consultoria',
    MINHA_ABORDAGEM: 'Minha Abordagem',
    CONTATO: 'Contato',
    ADULTO: 'Adulto',
    INFANTIL: 'Infantil',
    EBOOK: 'Ebook',
    AGENDE_SEU_HORARIO: 'Agende sua sessão',
};
