import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    appBar: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
    },
    drawer: {
        '& .MuiPaper-root': {
            backgroundColor: theme.appColors.white,
            opacity: 1,
        },
        '& a': {
            color: 'black',
        },
    },
    recuoEsquerda: {
        paddingLeft: theme.spacing(4),
    },
    containerAgendeSeuHorario: {
        display: 'flex',
        alignItems: 'center',
    },
    menuAgendeHorario: {
        padding: theme.spacing(2),
        backgroundColor: `${theme.appColors.whatsGreen} !important`,
        '& a': {
            color: `#fff !important`,
        },
        '& p': {
            marginLeft: 16,
        },
    },
    logo: {
        width: 40,
        height: 40,
        position: 'absolute',
        left: '50%',
        right: '50%',
        transform: 'translate(-50%, 0)',
    },
}));
