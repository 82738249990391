import { makeStyles } from '@material-ui/core';
import foto from '../../assets/img/foto-psicoterapia.jpg';

export const useStyles = makeStyles((theme) => ({
    gridContainer: {
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
        },
    },
    subContainer: {
        padding: theme.spacing(10),
        [theme.breakpoints.only('sm')]: {
            padding: theme.spacing(8),
        },
        [theme.breakpoints.only('xs')]: {
            padding: theme.spacing(4),
        },

        '& > :nth-child(1)': {
            paddingTop: 0,
        },
    },
    gridScheduleBtn: {
        padding: theme.spacing(10, 0),
    },

    subTitulo: {
        fontSize: 24,
        width: '100%',
        textAlign: 'left',
        lineHeight: '1.8em',
        padding: theme.spacing(3, 0),
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('md')]: {
            whiteSpace: 'pre-wrap',
        },
    },
    txtDescricao: {
        textAlign: 'justify',
        lineHeight: '1.8em',
        textIndent: '2em',
        fontSize: 16,
        paddingBottom: theme.spacing(3),
    },

    fotoPrincipal: {
        width: '100%',
        height: '100%',
        minHeight: '1350px',
        backgroundImage: `url(${foto})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',

        [theme.breakpoints.down('sm')]: {
            height: '700px',
        },
    },
}));
