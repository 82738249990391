import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    gridContainer: {
        width: '80%',
        maxWidth: '1440px',
        padding: theme.spacing(6),

        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },

    txtPrincipal: {
        textAlign: 'justify',
        lineHeight: '1.8em',
        fontSize: '1em',
    },

    divider: {
        marginTop: theme.spacing(6),
    },
}));
