export const TXT_SOBRE_MIM = {
    QUEM_SOU_EU: 'Quem sou eu?',
    PARAGRAFO_1: `Prazer! Meu nome é Laís Ferreira, mas algumas pessoas me chamam de La. Sou natural de Juiz de Fora – Minas Gerais. Sou filha, esposa e psicóloga.
Minha jornada na psicologia começou com uma paixão por compreender a complexidade da mente humana. Me formei em 2020 e logo já iniciei a pós graduação em Terapia Cognitivo Comportamental na infância e adolescência , atualmente sou Psicóloga Clínica e amo atender adolescentes e adultos.`,
    PARAGRAFO_2: `Possuo um repertório diversificado que permite uma abordagem personalizada e eficaz para atender às necessidades únicas de cada paciente, desenvolvendo estratégias práticas para assumir o controle da sua vida. Minha missão é criar um ambiente seguro e acolhedor, onde você possa explorar suas emoções e desafios sem julgamento. Acredito que a mudança positiva é possível para todos, e estou aqui para apoiar sua jornada rumo ao bem-estar emocional duradouro. Estou totalmente entregue e disposta a te auxiliar nesse caminho da construção da realidade que você quer.`,

    LINHA_DO_TEMPO: 'Linha do Tempo Psicologia',
    GRADUACAO_1:
        'Graduação em Psicologia I Graduada em Psicologia em 2020, na Universidade Salgado de Oliveira. Ao longo da graduação, se especializou na clínica cognitivo comportamental e também na área de Saúde Mental, atuando por um ano e meio na área de transtornos mentais como TEA.',
    ESPECIALIZACAO_1:
        'Especialização em Terapia Cognitivo Comportamental na Infância e Adolescência I Conluio em 2023 sua especialização onde aprofundou por dois anos, teoria e prática, o que embasou seu início profissional na psicologia pela Child Behavior Institute Of Miami (CBI OF MIAMI).',
    ESPECIALIZACAO_ATUAL:
        'Atualmente, está se aprofundando em Terapia de Aceitação e Compromisso (ACT) I Formação ACT na Prática clínica:Método das Narrativas Funcionais.',
};

export const TXT_REFLEXAO = {
    REFLEXAO: 'Pare e dê uma chance a si mesmo.',
    AUTOR_REFLEXAO: 'Aaron Beck',
};
