import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: 'transparent',
        height: '130px',
    },
    containerIcons: {
        maxWidth: '80px',
        margin: theme.spacing(2),
        '& a': {
            color: 'black',
        },
    },
    copyRight: {
        color: theme.appColors.gray,
        fontSize: '0.7rem',
    },
    tikTok: {
        height: '16px',
        width: '16px',
    },
}));
