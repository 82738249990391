import React from 'react';
import whats from '../../assets/img/whatsapp-64.png';
import { useStyles } from './styles';
import { WHATSAPP_ATENDIMENTO_TXT } from '../../constants/text';

export const BotaoFlutuanteWhats = () => {
    const classes = useStyles();

    return (
        <a
            href={WHATSAPP_ATENDIMENTO_TXT}
            target="_blank"
            rel="noreferrer"
            className={classes.whats}
        >
            <img src={whats} alt="botao whatsapp" className={classes.whats} />;
        </a>
    );
};
