import React from 'react';

export const TXT_PAGINA_CONSULTORIA = {
    ME_FORMEI_E_AGORA: 'Me formei, e agora?',
    RESPOSTA_ME_FORMEI:
        'Uma consultoria exclusiva para você, estudante de psicologia ou Psicólogo(a) , que deseja mais confiança nos próximos passos da clínica.',
    PRIMEIROS_PASSOS: `Uma consultoria comigo sobre os primeiros passos da clínica.`,
    RESPOSTA_PRIMEIROS_PASSOS: `Laís Ferreira, é formada em Psicologia, sendo considerada aluna destaque da sua turma na Universidade Salgado de Oliveira (Universo - MG). Pós-graduanda em Terapia Cognitivo- Comportamental na Infância e Adolescência pela CBI of Miami, formada em Terapia de Aceitação e Compromisso e tem se dedicado no consultório digital, onde atende clientes do mundo todo.  A partir dos conhecimentos que adquiriu na sua trajetória desde 2020, junto a superações pessoais e investimentos que fez na sua carreira, desenvolveu a consultoria para ajudar estudantes e psicólogos(as) a se sentirem mais seguros ao iniciarem sua carreira na clínica e no digital.`,
    O_QUE_ENCONTRAR: `O que você vai encontrar ao longo da nossa consultoria:`,
    ITEM_1: `O que você precisa saber e as estratégias básicas para seus atendimentos no digital ou no presencial.`,
    ITEM_2: `Algumas dicas valiosas sobre como usar as suas redes sociais para captação de clientes.`,
    ITEM_3: `Estratégias práticas para fazer da rede social seu cartão de visitas e te impulsionar no digital.`,
    ITEM_4: `Ferramentas e dicas para fazer o prontuário e os melhores apps.`,
    ITEM_5: `Precificação e qual valor cobrar no começo.`,
    ITEM_6: `Organizando sua agenda e rotina como psicóloga.`,
    ITEM_7: `Além de todos esses tópicos, teremos um tempo para uma conversa, tirando suas dúvidas e curiosidades sobre esse mundo dos atendimentos e da psicologia.`,
    BONUS: '+ BÔNUS!',
    ENTENDA_MELHOR: `Entenda melhor sobre a consultoria:`,

    FRASE_FINAL: `Se você está disposto(a) a investir na consultoria PSI DO ZERO, entre em contato comigo para agendarmos. Eu quero te dar as mãos para que você se sinta segura e realizada profissionalmente. Conte comigo nesse processo!`,
};

export const PERGUNTAS_CONSULTORIA = [
    {
        id: 0,
        PERGUNTA: `O que é?`,
        RESPOSTA: (
            <p>
                A consultoria <b>PSI DO ZERO</b> é uma consultoria individual
                para estudantes de psicologia e recém formados que desejam
                iniciar com mais confiança as atividades na clínica. É normal
                nos sentirmos inseguras ou sem saber por onde começar. E eu
                quero muito te ajudar a trilhar esse caminho com coisas que
                funcionaram muito na minha jornada como psicólogo(a) e de outras
                psicólogos(as) que eu acompanho.
            </p>
        ),
    },
    {
        id: 1,
        PERGUNTA: `Para quem é?`,
        RESPOSTA: (
            <p>
                Para estudantes de psicologia e recém formados que desejam
                entender melhor como a clínica digital ou presencial funciona na
                prática, dicas do que fazer e não fazer para começar esse
                processo com mais confiança.
            </p>
        ),
    },
    {
        id: 2,
        PERGUNTA: `Como funciona?`,
        RESPOSTA: (
            <>
                <p>
                    A consultoria é feita de forma individual via Zoom e terá em
                    média 2:00h, agendada em data e horário previamente
                    combinados. Antes disso, será mandado um formulário para que
                    eu possa entender a sua singularidade como psicólogo(a) e
                    poder te instruir em cima disso no dia da nossa consultoria.
                    Após a consultoria você terá acesso a um e-book com todas as
                    dicas práticas, uma semana comigo no WhatsApp para
                    acompanhamento e tirar dúvidas, além do nosso encontro e os
                    <b> BÔNUS:</b>
                </p>

                <ul>
                    <li>
                        plataforma no Notion com um modelo de prontuário que eu
                        desenvolvi para te ajudar.
                    </li>
                    <li>
                        Pack Canva para Psis que vão te impulsionar e facilitar
                        os primeiros conteúdos na rede social.
                    </li>
                </ul>
            </>
        ),
    },
];
