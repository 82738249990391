import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import PerguntasFrequentes from '../../components/PerguntasFrequentes';
import { ScheduleButton } from '../../components/ScheduleButton';
import { TXT_PAGINA_PSICOTERAPIA } from '../../constants/psicoterapia';
import foto from '../../assets/img/foto-psicoterapia.jpg';

import { Title } from '../../components/Title';
import ROUTES_NAME from '../../constants/routes-name';
import { useStyles } from './styles';
import LazyImage from '../../components/LazyImage';

export default function PsicoterapiaPage() {
    const classes = useStyles();

    return (
        <Grid
            container
            className={classes.gridContainer}
            id={ROUTES_NAME.PSICOTERAPIA}
        >
            <Grid
                item
                container
                xs={12}
                md={6}
                justify="center"
                alignContent="flex-start"
                className={classes.subContainer}
                id={ROUTES_NAME.PSICOTERAPIA}
            >
                <Title title={TXT_PAGINA_PSICOTERAPIA.TITULO_PSICOTERAPIA_1} />

                <Typography className={classes.txtDescricao}>
                    {TXT_PAGINA_PSICOTERAPIA.TXT_FOCO}
                </Typography>

                <Title title={TXT_PAGINA_PSICOTERAPIA.TITULO_PSICOTERAPIA_2} />
                <Typography className={classes.txtDescricao}>
                    {TXT_PAGINA_PSICOTERAPIA.TXT_PUBLICO_ATENDIDO}
                </Typography>

                <Title title={TXT_PAGINA_PSICOTERAPIA.TITULO_PSICOTERAPIA_3} />
                <Typography className={classes.txtDescricao}>
                    {TXT_PAGINA_PSICOTERAPIA.TXT_QUAL_MINHA_ABORDAGEM}
                </Typography>

                <Grid
                    item
                    container
                    xs={12}
                    justify="center"
                    alignContent="center"
                    className={classes.gridScheduleBtn}
                >
                    <ScheduleButton />
                </Grid>

                <PerguntasFrequentes />
            </Grid>
            <Grid item xs={12} md={6}>
                <LazyImage urlFoto={foto} />
            </Grid>
        </Grid>
    );
}
