const ROUTES_NAME = {
    HOME: '/home',
    SOBRE_MIM: 'sobre-mim',
    PSICOTERAPIA: 'psicoterapia',
    CONSULTORIA: 'consultoria',
    MINHA_ABORDAGEM: 'minha-abordagem',
    CONTATO: '/contato',
    EBOOK: '/ebook',
    PAGE_NOT_FOUND: '*',
};

export default ROUTES_NAME;
