import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    titulo: {
        fontWeight: 'bold',
        width: '100%',
        fontSize: 32,
        textAlign: 'center',
        lineHeight: '1.8em',
        padding: theme.spacing(3, 0),
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left',
            whiteSpace: 'pre-wrap',
        },
    },
    txtPergunta: {
        fontSize: 18,
        textAlign: 'left',
        lineHeight: '1.8em',
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('md')]: {
            whiteSpace: 'pre-wrap',
        },
    },
    txtResposta: {
        textAlign: 'justify',
        lineHeight: '1.8em',
        textIndent: '2em',
        fontSize: 14,
    },
}));
