import { ptBR } from '@material-ui/core/locale';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

export const primaryColor = '#797a7a';
export const secondaryColor = '#DE7749';
export const menuBg = 'rgba(255, 255, 255, 0.4)';
export const bege = '#EADDCB';
export const gray = '#797a7a';
export const white = '#fafafa';
export const whatsGreen = '#25D366';

const AppTheme = createMuiTheme(
    {
        palette: {
            primary: {
                main: primaryColor,
            },
            secondary: {
                main: secondaryColor,
            },
        },
        appColors: {
            primaryColor,
            secondaryColor,
            menuBg,
            gray,
            bege,
            white,
            whatsGreen,
        },
        typography: {
            fontFamily: ['QuickSand-Regular'],
        },
        overrides: {
            MuiCssBaseline: {
                '@global': {
                    html: {
                        WebkitFontSmoothing: 'auto',
                    },
                    a: {
                        textDecoration: 'none',
                    },
                },
            },
        },
    },
    ptBR
);

export default AppTheme;
