import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';
import PersonIcon from '@material-ui/icons/Person';
import React from 'react';
import { useStyles } from './styles';

export const CardDepoimento = ({ depoimento, autor }) => {
    const classes = useStyles();

    return (
        <Grid container className={classes.cardContainer} justify="center">
            <Paper elevation={3} className={classes.paperContainer}>
                <Grid
                    container
                    justify="center"
                    className={classes.depoimentoContainer}
                >
                    <Grid container className={classes.quote}>
                        <FormatQuoteIcon />
                    </Grid>
                    <Typography>{depoimento}</Typography>
                </Grid>

                <Grid
                    container
                    alignItems="center"
                    className={classes.avatarContainer}
                >
                    <Avatar>
                        <PersonIcon className={classes.person} />
                    </Avatar>
                    <Typography className={classes.autorTxt}>
                        {autor}
                    </Typography>
                </Grid>
            </Paper>
        </Grid>
    );
};
