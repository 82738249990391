import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    gridContainer: {
        minHeight: '50vh',
        height: '100%',
        [theme.breakpoints.down('md')]: {},
        [theme.breakpoints.down('sm')]: {},
    },
}));
