import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
    PERGUNTAS_FREQUENTES,
    TXT_PAGINA_PSICOTERAPIA,
} from '../../constants/psicoterapia';
import { Accordion, AccordionDetails, AccordionSummary } from '../Accordion';
import { useStyles } from './styles';
import { Title } from '../Title';

export default function PerguntasFrequentes() {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(undefined);

    const handleChange = (panel) => (_, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <Grid item container xs={12}>
            <Grid container justify="center">
                <Title
                    title={TXT_PAGINA_PSICOTERAPIA.TITULO_PSICOTERAPIA_4}
                    isSubTitle
                />
            </Grid>

            {PERGUNTAS_FREQUENTES.map((item) => (
                <Accordion
                    key={item.id}
                    square
                    expanded={expanded === item.id}
                    onChange={handleChange(item.id)}
                >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography className={classes.txtPergunta}>
                            {item.PERGUNTA}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography className={classes.txtResposta}>
                            {item.RESPOSTA}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            ))}
        </Grid>
    );
}
