import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React from 'react';
import { TXT_PAGINA_CONSULTORIA } from '../../constants/consultoria';
import foto from '../../assets/img/foto-consultoria.png';

import PerguntasConsultoria from '../../components/PerguntasConsultoria';
import { ScheduleButton } from '../../components/ScheduleButton/index';
import { Title } from '../../components/Title';
import ROUTES_NAME from '../../constants/routes-name';
import { useStyles } from './styles';
import LazyImage from '../../components/LazyImage';

export default function ConsultoriaPage() {
    const classes = useStyles();

    return (
        <Grid
            container
            className={classes.gridContainer}
            id={ROUTES_NAME.CONSULTORIA}
        >
            <Grid
                item
                container
                xs={12}
                md={6}
                justify="center"
                alignContent="flex-start"
                className={classes.subContainer}
            >
                <Title title={TXT_PAGINA_CONSULTORIA.ME_FORMEI_E_AGORA} />

                <Typography className={classes.txtDescricao}>
                    {TXT_PAGINA_CONSULTORIA.RESPOSTA_ME_FORMEI}
                </Typography>

                <Title title={TXT_PAGINA_CONSULTORIA.PRIMEIROS_PASSOS} />

                <Typography className={classes.txtDescricao}>
                    {TXT_PAGINA_CONSULTORIA.RESPOSTA_PRIMEIROS_PASSOS}
                </Typography>

                <Title title={TXT_PAGINA_CONSULTORIA.O_QUE_ENCONTRAR} />

                <ul className={classes.lista}>
                    <li>
                        <Typography className={classes.txtLista}>
                            {TXT_PAGINA_CONSULTORIA.ITEM_1}
                        </Typography>
                    </li>
                    <li>
                        <Typography className={classes.txtLista}>
                            {TXT_PAGINA_CONSULTORIA.ITEM_2}
                        </Typography>
                    </li>
                    <li>
                        <Typography className={classes.txtLista}>
                            {TXT_PAGINA_CONSULTORIA.ITEM_3}
                        </Typography>
                    </li>
                    <li>
                        <Typography className={classes.txtLista}>
                            {TXT_PAGINA_CONSULTORIA.ITEM_4}
                        </Typography>
                    </li>
                    <li>
                        <Typography className={classes.txtLista}>
                            {TXT_PAGINA_CONSULTORIA.ITEM_5}
                        </Typography>
                    </li>
                    <li>
                        <Typography className={classes.txtLista}>
                            {TXT_PAGINA_CONSULTORIA.ITEM_6}
                        </Typography>
                    </li>
                    <li>
                        <Typography className={classes.txtLista}>
                            {TXT_PAGINA_CONSULTORIA.ITEM_7}
                        </Typography>
                    </li>
                    <li>
                        <Typography
                            className={clsx(classes.bold, classes.txtLista)}
                        >
                            {TXT_PAGINA_CONSULTORIA.BONUS}
                        </Typography>
                    </li>
                </ul>

                <PerguntasConsultoria />

                <Typography className={classes.textFraseFinal}>
                    {TXT_PAGINA_CONSULTORIA.FRASE_FINAL}
                </Typography>

                <Grid
                    container
                    justify="center"
                    className={classes.scheduleButton}
                >
                    <ScheduleButton isConsultoria />
                </Grid>
            </Grid>

            <Grid item xs={12} md={6}>
                <LazyImage urlFoto={foto} />
            </Grid>
        </Grid>
    );
}
