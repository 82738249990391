import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    cardContainer: {
        backgroundColor: 'transparent',
        maxHeight: '90%',
    },
    paperContainer: {
        maxWidth: 600,
        [theme.breakpoints.down('sm')]: {
            maxWidth: '90%',
        },
    },
    quote: {
        '& > svg': {
            height: 56,
            width: 56,
            color: theme.palette.primary.main,
            rotate: '180deg',
        },
    },

    depoimentoContainer: {
        padding: theme.spacing(3),
    },
    avatarContainer: {
        borderTop: '1px solid rgb(147, 148, 148, 0.1)',
        backgroundColor: 'rgb(147, 148, 148, 0.1)',
        padding: theme.spacing(3),
    },
    person: {
        height: 32,
        width: 32,
    },
    autorTxt: {
        marginLeft: theme.spacing(4),
        fontWeight: 'bold',
    },
}));
