export const MINHA_ABORDAGEM = {
    TITLE: 'Terapia Cognitivo-Comportamental',
    DESCRIPTION: `A terapia cognitivo-comportamental (TCC) surgiu no final da década de 1950 e tem
    sido considerada efetiva em vários tipos de transtornos, diminuindo consideravelmente os diversos sintomas associados a esses.
    Além disso, A TCC é uma das abordagens das psicoterapias mais estudadas atualmente de maneira científica, demonstrando sua
    relevância no sucesso de diversos casos e transtornos.
    Essa abordagem de terapia utiliza ao mesmo tempo intervenções cognitivas, como as técnicas destinadas à identificação e correção de crenças e pensamentos disfuncionais,
    quanto técnicas comportamentais focadas na mudança de comportamento, como exposição e técnicas de relaxamento.
    Apesar de muito se falar sobre transtornos na TCC, ressalta-se sua capacidade para além disso: auxiliar pessoas em seu desenvolvimento
    pessoal e melhorar sua forma de lidar com as questões cotidianas de maneira geral. Dessa forma, a terapia também tem um olhar preventivo sobre a saúde mental,
    buscando ajudar as pessoas a melhorar relacionamentos, gerenciar suas emoções e potencializar suas virtudes para uma vida mais leve.`,
};
