import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import Grid from '@material-ui/core/Grid';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { TXT_DEPOIMENTOS } from '../../constants/depoimentos';
import { CardDepoimento } from './CardDepoimento';
import { useStyles } from './styles';
import { Title } from '../Title';

const Depoimentos = () => {
    const classes = useStyles();

    return (
        <Grid container justify="center" className={classes.container}>
            <Grid container justify="center">
                <Title title={TXT_DEPOIMENTOS.DEPOIMENTOS} />
            </Grid>

            <Carousel
                showThumbs={false}
                showStatus={false}
                autoPlay={false}
                showIndicators={false}
                stopOnHover
                infiniteLoop
                centerMode
                renderArrowPrev={(onClickHandler, hasPrev) =>
                    hasPrev && (
                        <ChevronLeftIcon
                            onClick={onClickHandler}
                            className={classes.arrow}
                            style={{ left: 15 }}
                        />
                    )
                }
                renderArrowNext={(onClickHandler, hasNext) =>
                    hasNext && (
                        <ChevronRightIcon
                            onClick={onClickHandler}
                            className={classes.arrow}
                            style={{ right: 15 }}
                        />
                    )
                }
            >
                <CardDepoimento
                    depoimento={TXT_DEPOIMENTOS.DEPOIMENTO_1}
                    autor={TXT_DEPOIMENTOS.AUTOR_1}
                />
                <CardDepoimento
                    depoimento={TXT_DEPOIMENTOS.DEPOIMENTO_2}
                    autor={TXT_DEPOIMENTOS.AUTOR_2}
                />
                <CardDepoimento
                    depoimento={TXT_DEPOIMENTOS.DEPOIMENTO_3}
                    autor={TXT_DEPOIMENTOS.AUTOR_3}
                />
                <CardDepoimento
                    depoimento={TXT_DEPOIMENTOS.DEPOIMENTO_4}
                    autor={TXT_DEPOIMENTOS.AUTOR_4}
                />
            </Carousel>
        </Grid>
    );
};

export default Depoimentos;
